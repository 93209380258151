"use client";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { toast } from "sonner";

const NotFound = () => {
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    setTimeout(() => {
      toast.warning(`Page "${pathname}" not found. Redirecting to home page.`, {
        id: "not-found-message",
      });
    }, 10);

    router.replace("/dashboard");
  }, [pathname, router]);

  return <></>;
};

export default NotFound;
